import FeaturesService from '@/src/common/services/features-service.js';
import { toCamelCase } from '@/src/util/service-mixins.js';

const featuresService = new FeaturesService();

const AppConfigStore = {
  state: {
    appConfig: {},
    featureFlippers: {},
  },

  mutations: {
    INITIALIZE_APP_CONFIG(state, appConfig) {
      state.appConfig = toCamelCase(appConfig);
    },

    INITIALIZE_FEATURE_FLIPPERS(state, featureFlippers) {
      state.featureFlippers = featureFlippers;
    },
  },

  actions: {
    initializeAppConfig(context, appConfig) {
      context.commit('INITIALIZE_APP_CONFIG', appConfig);
    },

    async initializeFeatureFlippers(context) {
      const featureFlippers = await featuresService.getFeatureFlippers();
      context.commit('INITIALIZE_FEATURE_FLIPPERS', featureFlippers);
    },
  },

  getters: {
    currentUser() {
      return toCamelCase(window.CURRENT_USER);
      // This user only has attributes defined in app/views/layouts/common/_current_user.html.erb
    },

    isCurrentUserAdmin(_state, getters) {
      return getters.currentUser.role === 'admin';
    },

    isCurrentUserManager(_state, getters) {
      return getters.currentUser.role === 'manager';
    },

    isCurrentUserTech(_state, getters) {
      return getters.currentUser.role === 'tech';
    },

    isCurrentUserOrgRestricted(_state, getters) {
      return getters.currentUser.isOrgRestricted;
    },
  },
};

export default Object.freeze(AppConfigStore);
